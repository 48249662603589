@import "src/styles/colors";

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.author {
    display: flex;
    gap: 16px 40px;
    padding: 16px 24px;
    flex-wrap: wrap;
}

.text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-top: 1px solid $gray-400;
    padding: 24px;
    white-space: pre-wrap;
    word-break: break-word;
}

.content {
    border-radius: 4px;
    border: 1px solid $gray-400;
}