@import "~bootstrap/scss/mixins/breakpoints";

.popover {
  min-width: 400px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, .25);
  border: 1px solid #D8E1EA !important;

  &.small {
    max-width: unset;
    min-width: auto;
  }
}

.popover.no-arrow .arrow {
  display: none !important;
}


@include media-breakpoint-down("sm") {
  .timeline .popover {
    display: none;
  }
}