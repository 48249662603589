@import "src/styles/colors";

.container {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $gray-600;
    font-size: 14px;
    font-weight: 600;
    max-width: 100%;
}

.name {
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.date {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.text {
    display: flex;
    gap: 6px;
    min-width: 0;
    max-width: 100%;
}