@import "src/styles/colors";

:local(.dialog) {
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  width: 100svw !important;
  height: 100svh !important;
  max-width: 100vw !important;
  max-height: 100svh !important;
  min-height: 0 !important;

  .bodyContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .statusFilterButtonsContainer {
      .statusFilterButtonsTitle {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        white-space: nowrap;
      }

      .statusFilterButtonsWrapper {
        margin-top: 12px;
        display: flex;
        flex-wrap: nowrap;
        gap: 12px;
      }
    }
  }

  .dialogFooter {
    padding: 28px !important;
  }
}