:local(.loaderContainer) {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

:local(.loader) {
  width: 100px;
  height: 100px;
}
