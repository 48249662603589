@import 'src/styles/colors';

:local(.servicesGroup) {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  margin: 0 -48px;

  @media(max-width: 991.98px) {
    margin: 0;
  }
}

:local(.noServicesMessage) {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  color: $gray-600;
  margin: 10px 0 0;
}
