@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$modal-backdrop-opacity: 0.87;
$modal-content-border-color: transparent;
$modal-footer-border-color: $gray-400;
$modal-inner-padding: 1.75rem;
$modal-header-padding: 1.25rem 1.75rem;
$zindex-modal-backdrop: 990;

.modal {
  .modal-header {
    box-shadow: none;
    border-bottom: none;

    .close {
      position: relative;
      top: 2px;
      padding: 1.25rem 1.75rem;
      margin: -1.25rem -1.75rem -1.25rem auto;
      outline: none;

      &::after {
        font-size: 22px;
      }
    }
  }

  .modal-footer {
    border-top: none !important;
  }

  @include media-breakpoint-down("sm") {
    .modal-dialog {
      margin: 0;
      max-width: 100%;
      min-height: 100vh;
      height: auto !important;

      .modal-content {
        width: 100vw;
        min-height: 100vh !important;
        height: 100%;
        border-radius: 0;

        .modal-header {
          box-shadow: none;
        }
      }
    }
  }
}