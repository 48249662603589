@import "src/styles/colors.scss";

:local(.sidebarWrapper) {
  position: relative;
  width: 300px;
  transition: max-width .1s ease;

  flex-shrink: 0;

  &.sidebarIsHidden {
    max-width: 0;
  }

  .showSidebarControlWrapper {
    position: absolute;
    height: 2px;
    width: 100%;
    background: $yellow;

    .showSidebarControlButton {
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 1;

      padding: 4px 0 !important;
      border-radius: 0 4px 4px 0 !important;
    }
  }

  .sidebarBodyWrapper {
    top: 0;
    max-height: calc(100vh - 99px - 64px);
  }

  .localesDropdown {
    position: fixed;
    left: 20px;
    bottom: 20px;
    background: #fff;
  }
}

:local(.objectsCollapse) {
  height: calc(40vh - 50px);
  overflow-y: auto;
}

.shopsDropdown {
  height: 60px;
  box-shadow: 0 3px 10px 0 rgba(51, 62, 73, 0.1);
}