@import "src/styles/colors";

:local(.dialog) {
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  width: 100svw !important;
  height: 100svh !important;
  max-width: 100vw !important;
  max-height: 100svh !important;
  min-height: 0 !important;

  .dialogFooter {
    padding: 28px !important;
  }
}