@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints.scss";

:local(.description) {
  font-family: 'Mulish';
  font-weight: 400;
  color: #202024;
  white-space: break-spaces;
}

:local(.buttonWrapper) {
  padding: 20px 28px !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  gap: 16px;

  @include media-breakpoint-down("sm") {
    margin-top: auto;
  }
}