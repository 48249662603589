@import 'src/styles/colors';

.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    width: 100%;
    min-height: 72px;
    padding: 8px 20px;
    border-radius: 4px;

    background-color: $gray-200;
}