@import "src/styles/colors";

:local(.container) {
  overflow: hidden;
  padding-inline-start: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  margin: 0;
  cursor: pointer;
  width: calc(100% - 1px);
  height: calc(100% - 1px);

  &:local(.hoverable):hover {
    background-color: $gray-300;
  }

  :local(.fullName) {
    margin-left: 12px !important;
    color: $gray-600;
    font-weight: 600;
  }

  :local(.booking) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::before {
      position: relative;
      top: -1.5px;
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: transparent;
      margin-right: 6px;
    }

    &:local(.inProgress)::before {
      background-color: $blue;
    }

    &:local(.paid)::before,
    &:local(.full)::before {
      background-color: $green;
    }

    &:local(.paidAfterwards)::before,
    &:local(.cancelled)::before {
      background-color: $red;
    }

    &:local(.notPresent)::before {
      background-color: $black;
    }

    &:not(:local(.paid)):not(:local(.paidAfterwards)):not(:local(.full)):not(:local(.cancelled))::before {
      background-color: $gray-400;
    }

    &:local(.cancelled) {
      text-decoration: line-through;
    }
  }
}
