@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.hideScroll {
  -ms-overflow-style: none !important;
  /* for Internet Explorer, Edge */
  scrollbar-width: none !important;

  /* for Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
}

.desktopContainer {
  display: flex;
  width: 225px;
  padding: 12px 0;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 6px;
  border: 1px solid $gray-400;
  background: $white;
}