@import 'src/styles/colors';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";


.divider {
    width: 1px;
    height: 60px;
    background: $gray-400;
}