:local(.flex) {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin: 30px 30px 90px 30px !important;

  &>h1 {
    font-size: 3rem;
  }
}

:local(.footer) {
  padding: 10px;
}
