@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.balanceIcon {
  color: $primary;

  &.grayColor {
    color: $gray-500;
  }
}

.point,
.date {
  color: #202024;
}