@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.form {
    max-width: 760px;
    width: 100%;
    padding-bottom: 81px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    [class='form-control'] {
        height: 40px !important;
    }

    [class='input-group'] {
        [class='input-group-prepend'] {
            &>div {
                &>div {
                    border-radius: 0.25rem 0 0 0.25rem;
                }
            }
        }

        [class='input-group-append'] {
            &>span {
                border-radius: 0 0.25rem 0.25rem 0 !important;
            }
        }
    }

    .label {
        margin-bottom: 12px;

        display: flex;
        align-items: center;

        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }

    .groupHeader {
        margin-bottom: 24px;

        display: flex;
        align-items: center;

        font-size: 20px;
        font-weight: 600;
    }

    .groupSubHeader {
        margin-top: 8px;

        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
    }

    .divider {
        margin: 32px 0;
    }

    .multipleGroupWrapper {
        display: flex;
        justify-content: space-between;
        gap: 24px;
    }

    .addressGroupWrapper {
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 800px;

        .addressActionsWrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        .zipAndCityWrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        .addressWrapper {
            width: calc(50% - 12px);
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        .mapWrapper {
            width: calc(50% - 12px);
            height: 504px;
        }
    }


    .contactsGroupWrapper {
        flex-wrap: wrap;

        &>div {
            width: calc((100% - 24px) / 2);

            &:last-child {
                width: 100%;
            }
        }
    }

    .bookingsGroupWrapper {
        flex-wrap: wrap;

        &>div {
            width: calc((100% - 48px) / 3);

            &:nth-child(2) {
                width: 100%;
            }
        }
    }

    .workBreaksLabel {
        margin: 0;
        margin-top: 24px;

        display: flex;
        align-items: center;
        gap: 10px;

        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        cursor: pointer;
    }

    .workBreaks {
        margin-top: 24px;
    }
}

.actionsButtonsWrapper {
    position: fixed;
    background-color: $white;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 40px;
    border-top: 1px solid $gray-200;

    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

@include media-breakpoint-down("sm") {
    .form {
        .contactsGroupWrapper {
            &>div {
                width: 100%;
            }
        }

        .bookingsGroupWrapper {
            flex-direction: column;

            &>div {
                width: 100%;
                max-width: 231px;

                &:nth-child(2) {
                    max-width: 100%;
                }
            }
        }

        .addressGroupWrapper {
            max-height: unset;

            .addressWrapper {
                width: 100%;
            }

            .mapWrapper {
                height: 300px;
                width: 100%;
            }

            .zipAndCityWrapper {
                flex-direction: row;
            }

            .addressActionsWrapper {
                order: 3;
            }
        }
    }
}