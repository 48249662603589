@import "src/styles/colors";

:local(.label) {
  display: block;
  width: 44px;
  flex-shrink: 0;
}

:local(.day) {
  height: 215px;
  cursor: pointer;

  &:hover {
    background-color: $gray-300;
  }
}
