@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$basis-options: (
  '100': 100%,
  '75': 75%,
  '50': 50%,
  '25': 25%,
  'auto': auto
);

@each $name, $value in $basis-options {
  .flex-basis-#{$name} {
    flex-basis: $value;
  }

  @each $breakpoint, $breakpoint-value in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .flex-#{$breakpoint}-basis-#{$name} {
        flex-basis: $value;
      }
    }
  }
}