@import 'src/styles/colors';

:local(.avatarContainer) {
  margin: 32px 40px !important;

  width: 232px;
  height: 232px;

  @media (max-width: 640px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

:local(.avatar) {
  width: 100%;
}

:local(.profileInfoLines) {
  border-right: 1px solid $gray-200;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 685px) {
    margin-left: 40px !important;
  }

  :local(.profileLine) {
    margin: 0 40px 24px 0;
    font-weight: 600;
    flex-wrap: nowrap !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :local(.icon) {
    color: $gray-500;
  }
}

:local(.wideContent) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

:local(.address) {
  max-width: 200px;
}