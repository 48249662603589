$dropdown-bg: #FFFFFF;
$dropdown-border-width: 0;
$dropdown-link-hover-bg: transparent;

.dropdown-toggle.no-arrow::after {
  display: none !important;
}

.dropdown-toggle.p-20px {
  padding: 0 20px !important;
}

.dropdown-toggle.lang {
  padding: 8px !important;
  padding-left: calc(8px + 3px) !important;
  display: flex !important;
  align-self: center !important;
  background-color: transparent !important;

  &:focus {
    outline: none !important;
    box-shadow: unset !important;
  }
}

.dropdown-menu {
  box-shadow: 0 10px 20px 0 rgba(51, 62, 73, 0.1);

  .dropdown-item {
    padding-right: 20px;
    padding-left: calc(17px + 3px);
    font-weight: 600;

    &:active,
    &.active {
      background-color: transparent;
      color: #000000;
    }

    &:hover,
    &:active,
    &.active {
      border-left: 3px solid theme-color();
      padding-left: 17px;
    }
  }

  .dropdown-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .dropdown-item.lang {
    padding: 8px !important;
    padding-left: calc(8px + 3px) !important;
    display: flex !important;
    align-self: center !important;

    &:hover,
    &:active,
    &.active {
      padding-left: 8px !important;
    }
  }
}

.dropdown-menu.no-min-width {
  min-width: unset !important;
}