:local(.dialogHeader) {
  border: none !important;
  border-radius: 6px 6px 0 0 !important;
  padding: 24px 28px 0 !important;
  box-shadow: none !important;

  &:last-child {
    padding-bottom: 24px !important;
  }
}
