@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}