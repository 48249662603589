@import "colors.scss";
@import "~bootstrap/scss/mixins/breakpoints.scss";

$nav-link-column-padding-x: 0.75rem;
$nav-link-padding-x: 0;

.nav:not(.flex-column) .nav-link {
  margin: 0px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;

  &:hover,
  &:active {
    border-bottom: 3px solid $gray-400;
  }

  &.active {
    border-color: theme-color();
    color: theme-color();
  }
}

@include media-breakpoint-down("sm") {
  .nav .nav-link {
    font-size: 15px;
    margin: 0 0.5rem;
  }
}

.nav.flex-column .nav-link {
  border-left: 3px solid transparent;
  padding-left: calc(1.5rem);
  font-weight: 600;
  padding-bottom: $nav-link-column-padding-x;
  padding-top: $nav-link-column-padding-x;

  &:active,
  &.active {
    background-color: transparent;
    color: #000000;
  }

  &:hover,
  &:active,
  &.active {
    border-left: 3px solid theme-color();
    padding-left: 1.5rem;
  }
}