@import 'src/styles/colors';

:local(.headerRow) {
  border-bottom: 1px solid $gray-400;
  height: 56px;

  &:last-child {
    border-bottom: none;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}