@import "src/styles/colors";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}