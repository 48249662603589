@import 'src/styles/colors';

:local(.modal) {
  button:focus {
    outline: none;
  }
}

:local(.booking) {
  position: relative;
  padding-left: 18px;

  &:before {
    display: block;
    content: '';
    width: 12px;
    height: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #D8D8D8;
  }
}

:local(.modalHeader) {
  & button {
    opacity: 1;

    &:hover {
      opacity: 0.5 !important;
    }
  }
}

:local(.calendarIcon) {
  color: $gray-600;
}


.warn {
  border-radius: 4px;
  color: $yellow-100;
  background-color: $yellow-020;
}