@import 'src/styles/colors';

:local(.serviceForm) {
  border-bottom: 2px solid #E5EBF0;
}

:local(.unlimitedUsagesContainer) {
  @media (min-width: 992px) {
    margin-top: 36px;
  }
}

:local(.deleteButtonContainer) {
  margin-top: 30px;

  @media (max-width: 991px) {
    order: 999;
  }
}

:local(.missedUsagesWeeksContainer) {
  margin: 0 7px;
}