:local(.container) {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  max-width: 100%;

  @media (max-width: 1360px) {
    flex-direction: column;
  }

  :local(.column) {
    flex: calc(100% / 7);
  }

  :local(.head) {
    padding: 11px 21px;
    background-color: rgba(216, 225, 234, 0.17);
  }
}