@import "src/styles/colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-wrap: nowrap;
}

.period {
    text-wrap: nowrap;
}

.container {
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    background-color: $gray-400;
}


.summaryText {
    font-size: 12px;
    font-weight: 600;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.summaryNumber {
    font-size: 24px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.labelError {
    color: $red;
}

.labelGreen {
    color: $green;
}

.info {
    width: calc(100% - 52px);
}

.userContainer {
    width: 100%;
}