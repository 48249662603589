@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.table {
  border: 1px solid $gray-400;
  border-radius: 4px;

  display: flex;

  .tableColumn {
    width: calc(100% / 7);
    flex-shrink: 0;

    &:not(:first-child) {
      border-left: 1px solid $gray-400;
    }

    &:first-child {
      &>*:first-child {
        border-radius: 4px 0 0 0;
      }

      &>*:nth-child(3) {
        &>* {
          border-radius: 0 0 0 4px;
        }
      }
    }

    &:last-child {
      &>*:first-child {
        border-radius: 0 4px 0 0;
      }

      &>*:nth-child(3) {
        &>* {
          border-radius: 0 0 4px 0;
        }
      }
    }

    .headCell {
      margin: 0;
      height: 42px;
      padding: 9px 20px;
      background: $gray-100;

      display: flex;
      align-items: center;
      gap: 8px;

      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
    }

    .bodyCell {
      height: 42px;
      padding: 9px 20px;
      border: none;
      border-top: 1px solid $gray-400;
      border-radius: 0;
      box-shadow: none;
      outline: none;

      display: flex;
      align-items: center;

      font-size: 16px;
      font-weight: 400;
      line-height: 2.5;
      color: $light-black;
      cursor: pointer;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &.bodyCellEmpty {
        color: $gray-600;
      }
    }
  }
}

@include media-breakpoint-down("sm") {

  .table {
    flex-direction: column;

    .tableColumn {
      width: 100%;
      border-left: none !important;

      &:first-child .headCell {
        border: none;
      }

      &:first-child {
        &>*:first-child {
          border-radius: 4px 4px 0 0;
        }

        &>*:nth-child(3) {
          &>* {
            border-radius: 0;
          }
        }
      }

      &:last-child {
        &>*:first-child {
          border-radius: 0;
        }

        &>*:nth-child(3) {
          &>* {
            border-radius: 0 0 4px 4px;
          }
        }
      }

      .headCell {
        border-top: 1px solid $gray-400;
      }
    }
  }
}