$yellow: #FFC100;
$green: #00C102;
$red: #F4493E;
$red-dark: #C01B1B;
$red-light: #FEEDEC;
$blue: #23AAF6;
$light-black: #202024;
$white: #fff;

$primary: $yellow;
$primary-light: #FFF9E6;

$gray-100: #F9FAFB;
$gray-200: #E5EBF0;
$gray-300: #F6F6F6;
$gray-400: #D8E1EA;
$gray-500: #B3BDC8;
$gray-600: #818E9C;
$gray-400-17: #D8E1EA2D;
$gray-600-010: #818E9C1A;
$gray-600-015: #818E9C26;
$gray-600-005: #818E9C0D;
$gray-700: #566270;
$gray-800: #333E49;

$green-010: #00C1021A;
$blue-010: #23AAF61A;
$red-010: #FF00001A;

$black: #000;
$black-070: #000000b3;

$yellow-100: #EA9A00;
$yellow-020: #FFC10033;

$link-color: black;

$colors: (
  "yellow": $yellow,
  "green": $green,
  "red": $red,
  "red-dark": $red-dark,
  "blue": $blue,
  "light-gray": $gray-500,
  "gray": $gray-600,
  "dark-gray": $gray-700
);

$theme-colors: (
  "primary": $primary,
  "success": $green,
  "warning": $yellow,
  "danger": $red,
  "dark-danger": $red-dark,
  "info": $blue,
  "secondary": #ced4da,
  "gray-200": $gray-200,
  "very-light": $gray-300,
  "light": $gray-400,
  "darker-light": $gray-500,
  "dark-gray": $gray-700,
  "darker-gray": $gray-800,
  "dark": #000000
);

$grays: (
  "600": $gray-600
);

@function color($key: "blue") {
  @return map-get($colors, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

@function gray($key: "100") {
  @return map-get($grays, $key);
}

@function color-yiq($color) {
  @return #fff;
}