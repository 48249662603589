@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;

    /* becouse of actionsButtonsWrapper fixed */
    padding-bottom: 81px;

    .fileInput {
        font-size: 16px;
        max-width: 300px;

        .fileInputSub {
            display: block;
            font-size: 14px;
        }
    }
}

.actionsButtonsWrapper {
    position: fixed;
    background-color: $white;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 40px;
    border-top: 1px solid $gray-200;
    z-index: 101;

    display: flex;
    justify-content: flex-end;
    gap: 20px;
}