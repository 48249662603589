@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

@import "src/styles/colors";

.container {
  display: grid;
  grid-template-columns: 25px 25px calc(25% - 50px);

  &.month {
    border-left: 1px solid $gray-400;
  }

  @include media-breakpoint-down("md") {
    &.month {
      grid-template-columns: 100% !important;

      .day:nth-child(8) {
        border-top: 1px solid $gray-400;
      }
    }
  }

  &>div:not(.hourColumn) {
    border-right: 1px solid $gray-400;
  }

  &>div:not(.hourColumn):not(.spacer):not(.selected),
  &>.spacer:nth-child(2) {
    border-bottom: 1px solid $gray-400;
  }

  .hourColumn:not(:first-child) {
    position: relative;
    grid-column: 1;
    grid-row-start: span 1;


    .hour {
      position: relative;
      top: -0.9rem;
      color: $gray-600;
      font-size: 12px;
    }

    .currentTime {
      position: absolute;
      left: -5px;
      transform: translateY(-50%);
      padding: 3px 9px;
      background-color: theme-color();
      font-size: 12px;
      color: #FFFFFF;
      border-radius: 2px;
      z-index: 100;

      .line {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 2px;
        background-color: theme-color();
      }
    }
  }

  .spacer {
    margin-left: 5px;

    &:not(:nth-child(2)) {
      grid-column: 2;
      grid-row-start: span 1;
    }

    &.selectedNext {
      border-right: 1px solid $primary;
    }
  }

  .cell {
    min-height: 60px;
    cursor: pointer;
    transition: 0.15s all;

    &:not(.notBookable):hover {
      background-color: opacify($gray-200, 0.1);
    }

    &.notBookable {
      cursor: not-allowed;
      background-color: $gray-400-17;
    }
  }

  .productTitle {
    position: sticky;
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 0.5rem 0;
    z-index: 850;
    border-top: 1px solid $gray-400;
    background-color: $gray-100;

    &.month {
      font-size: 14px;
      text-transform: uppercase;
    }

    &.top0 {
      top: 0 !important
    }

    &.selected {
      background-color: $primary-light;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
      border-right: 1px solid $primary;
    }

    &.selectedNext {
      border-right: 1px solid $primary;
    }
  }

  .day {
    background-color: #FFFFFF;

    &.hoverable {
      cursor: pointer;

      &:hover {
        background-color: $gray-300;
      }
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      min-height: 34px;
      margin: 6px auto auto auto;
      text-align: center;

      &.selected {
        border: 2px solid $primary;
        border-radius: 50%;
      }
    }
  }

  @include media-breakpoint-down("md") {
    .day {
      padding-bottom: unset !important;
    }
  }

  @include media-breakpoint-down("sm") {
    .productTitle {
      top: 66px;
    }
  }

  @include media-breakpoint-up("md") {
    .productTitle {
      top: 72px;
    }
  }
}

.gray {
  color: $gray-500;
}