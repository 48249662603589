@import 'src/styles/colors';

:local(.bodyCell) {
  height: 56px;
  padding: 0 20px !important;
  border: none !important;
  vertical-align: middle !important;
  white-space: nowrap;
}

.left {
  text-align: left;

  >div,
  >button {
    margin-right: auto;
  }
}

.center {
  text-align: center;

  >div,
  >button {
    margin: 0 auto;
  }
}

.right {
  text-align: right;

  >div,
  >button {
    margin-left: auto;
  }
}

.noPadding {
  padding: 0 !important;
}