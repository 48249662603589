@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

@import "src/styles/colors";

:local(.content) {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  position: relative;

  .weekdayHeader {
    font-size: 14px;
    font-weight: bolder;
    text-align: center;
    height: 40px;
    padding: 8px;
  }

  :local(.weekday) {
    font-size: 14px;
    font-weight: 800;
  }

  :local(.line) {
    grid-column: 1 / 9;
    border-bottom: 1px solid $gray-200;
    width: 100%;
  }

  :local(.day) {
    border: none;
    background: transparent;
    border-radius: 50%;
    position: relative;
    aspect-ratio: 1;
    height: 100%;
    color: $gray-700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;

    &:focus {
      outline: none;
    }

    &:local(.disabled) {
      color: #B3BDC8;
      cursor: default;
    }

    &:local(.available):hover,
    &:local(.selected) {
      border-radius: 50%;
      background-color: theme-color() !important;

      span {
        color: #fff;
      }
    }

    &:local(.today) {
      border-radius: 50%;
      border: 2px solid theme-color() !important;
    }

    :local(.dots) {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);

      span {
        font-size: 11px;
      }
    }
  }

  .dayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :local(.weekday) {
    border: none;
    background: $gray-200;
    position: relative;
    height: 38px;
    color: $gray-700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  :local(.weeks) {
    position: absolute;
    top: 40px;
    left: 12.5%;
    width: 87.5%;
    height: calc(100% - 40px);
    z-index: -1;

    :local(.week) {
      height: 38px;
      cursor: pointer;

      &:local(.highlighted),
      &:hover {
        background-color: transparentize($primary, 0.9);
        border-radius: 100px;
      }
    }
  }
}