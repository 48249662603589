@import "src/styles/colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-down("md") {
        flex-direction: column;
    }
}