@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.description {
  max-width: 1116px;
  font-size: 16px;
  line-height: 24px;
  color: $gray-600;
}

.actionsContainer {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: nowrap;


  -ms-overflow-style: none !important;
  /* for Internet Explorer, Edge */
  scrollbar-width: none !important;

  /* for Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
}

.column {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  gap: 15px;
  padding: 12px 32px !important;
}

@include media-breakpoint-down("sm") {
  .column {
    padding: 12px !important;
  }
}