@import "src/styles/colors";

.button {
    outline: none !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    display: flex;
    box-sizing: border-box;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    gap: 10px;

    text-wrap: nowrap;
    font-style: normal;

    line-height: normal;

    width: fit-content;

    background: linear-gradient(#0000, rgba(0, 0, 0, 0.1)) top/100% 800%;
    transition: 0.5s;

    padding: 0 20px;
    font-weight: 700;
}

.group {
    &:not(&:first-child) {
        border-left: 0 !important;
    }

    &:not(&:only-child) {
        border-radius: 0 !important;


        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

.noPadding {
    padding: 0 !important;
}

.button:active {
    background-position: bottom;
}

@media(hover: hover) and (pointer: fine) {
    .button:hover {
        background-position: bottom;
    }
}

.link {
    text-decoration: none !important;
}

.yellow {
    background-color: $yellow !important;
    color: $white !important;
}

.black {
    background-color: $black !important;
    color: $white !important;
}

.gray {
    color: $black !important;
    background-color: $gray-600-015 !important;
}

.red {
    background-color: $red !important;
    color: $white !important;
}

.green {
    background-color: $green !important;
    color: $white !important;
}

.white {
    background-color: $white !important;
    color: $black !important;
}

.outline {
    color: $black !important;
    border: 1px solid $gray-400;
    background-color: transparent !important;
}

.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
    cursor: default !important;
}

.stretched {
    width: 100%;
}

.small {
    height: 32px;
    min-height: 32px;
    font-size: 14px;
    padding: 0 10px;
}

.medium {
    height: 40px;
    min-height: 40px;
    font-size: 14px;
}

.large {
    height: 48px;
    min-height: 48px;
    font-size: 14px;
}

.extraLarge {
    height: 60px;
    min-height: 60px;
    font-size: 14px;
}

.before {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.beforeBlack {
    color: $black !important;
}

.beforeWhite {
    color: $white !important;
}

.beforeRed {
    color: $red !important;
}

.red {
    background-color: $red !important;
    color: $white !important;
}

.outlineRed {
    color: $red !important;
    border: 1px solid $red;
    background-color: $white !important;
}