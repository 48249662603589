@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.body {
    display: flex;
    gap: 32px;

    .imageColumn {
        width: 240px;
        flex-shrink: 0;

        .image {
            width: 100%;
            border-radius: 6px;
        }
    }

    .contentColumn {
        width: calc(100% - 272px);

        font-size: 14px;
        font-weight: 600;

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3
        }

        .info {
            margin-top: 24px;

            display: flex;
            flex-wrap: wrap;
            gap: 16px 30px;

            .infoItem {
                display: flex;

                .infoItemTitle {
                    margin-right: 4px;
                    font-weight: 500;
                    color: $gray-600;
                    text-wrap: nowrap;
                }

                .infoItemUserListWrapper {
                    .infoItemUserList {
                        max-height: 63px;
                        overflow-y: hidden;
                        padding-right: 10px;

                        &.infoItemUserListWithScroll {
                            max-height: 126px;
                            overflow-y: auto;
                            transition: max-height .1s linear;
                        }
                    }
                }

                .infoItemVideoUrl {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                }
            }
        }

        .description {
            margin-top: 24px;

            font-weight: 500;
            word-break: break-word;
            white-space: break-spaces;
        }
    }
}

.greenColor {
    color: $green;
}

.grayColor {
    color: $gray-600;
}

.footer {
    display: flex;
    justify-content: space-between !important;
}

@include media-breakpoint-down("md") {
    .body {
        flex-direction: column;
        gap: 19px;

        .imageColumn {
            width: 100%;
        }

        .contentColumn {
            width: 100%;

            .info {
                flex-direction: column;
            }
        }
    }

    .footer {
        flex-direction: column;
        align-items: stretch !important;
        gap: 5px;

        .rightBlockWrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}