@import "src/styles/colors.scss";

.actionSheetItem {
    border: transparent !important;
    border-radius: 0 !important;
    justify-content: flex-start !important;
}

.actionSheet {
    min-width: 180px;
}

.actionSheet :not(:last-child) {
    border-bottom: 1px solid $gray-200 !important;
}