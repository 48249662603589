@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

:local(.mobileButtonsContainer) {
  @include media-breakpoint-up("lg") {
    display: none !important;
  }

  .footerDivider {
    width: 1px;
    height: 60px;
    background: $gray-400;
  }

  .submit {
    flex: 1;
    padding: 0 15px;
  }

  .yellowDot {
    width: 8px;
    height: 8px;
    background-color: $yellow;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    right: 14px;
  }
}