@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.periodDropdownToggle {
  padding: 0 16px !important;
  width: 103px;
  height: 32px;
  box-shadow: none !important;

  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: $black !important;

  &::after {
    color: $gray-600;
  }

  .periodDropdownMenu {
    min-width: 0;
    width: 100%;

    .periodDropdownMenuItem {
      padding: 4px 10px;

      text-transform: capitalize;
    }
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 2px);
  z-index: 1;

  width: 270px;
  background: #fff;
  border: 1px solid $gray-400;
  border-radius: 5px;

  &.autoWidth {
    width: auto;
  }

  .modalHeader {
    display: none;
  }

  .dropdownHeader {
    padding: 20px 10px;
    border-bottom: 1px solid $gray-400;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 800;
  }

  .dropdownBody {
    padding: 20px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 9px;

    &.dropdownBodyColumn {
      flex-direction: column;
      align-items: center;
    }
  }

  @include media-breakpoint-down("sm") {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    &.autoWidth {
      width: 100%;
    }

    .modalHeader {
      padding: 16px;
      border-bottom: 1px solid $gray-400;

      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
      color: $black;

      .closeButton {
        width: 20px;
        height: 20px;
        padding: 0;
        outline: none;
        color: $gray-600;
        display: flex;

        &:hover {
          color: $yellow;
        }
      }
    }

    .dropdownHeader {
      margin: 15px auto 0;
      width: 316px;
    }

    .dropdownBody {
      margin: 0 auto;
      width: 316px;
    }
  }
}