@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dialogBody {
  padding: 16px !important;

  @include media-breakpoint-up("lg") {
    padding: 20px 28px 0 !important;
  }

  &:last-child {
    padding-bottom: 24px !important;
  }
}