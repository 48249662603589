@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

:local(.tableContainer) {
  border-radius: 4px;
  border: 1px solid $gray-400;
  overflow: hidden;
  width: 100%;

  @include media-breakpoint-down("md") {
    border-radius: 0;

    [class=table-responsive] {
      -ms-overflow-style: none !important;
      /* for Internet Explorer, Edge */
      scrollbar-width: none !important;

      /* for Firefox */
      &::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
      }
    }
  }
}



:local(.table) {
  margin: 0 !important;
  padding: 0 !important;
}

.newItems {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid $gray-400;
  background-color: $gray-100;
}