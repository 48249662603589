@import "colors";

$btn-padding-x: 1.933rem;
$btn-padding-y: 0.532rem;
$btn-padding-y-sm: 0.438rem;
$btn-font-size: 14px;
$btn-font-size-sm: 12px;
$btn-font-weight: bold;

.btn {
  text-transform: uppercase;
  font-size: 14px;
  display: flex !important;
  align-items: center !important;

  &.btn-square {
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.disabled,
  &:disabled {
    opacity: .5 !important;
  }
}

.btn-sm-wide {
  padding: 0.5rem 1rem !important;
}

.dropdown-toggle::after {
  position: relative;
  top: 2px;

  font-family: 'feather', sans-serif;
  speak: none;
  font-style: normal;
  font-weight: bolder;
  font-variant: normal;
  text-transform: none;
  font-size: 17px;
  line-height: 1;
  border: 0 !important;
  color: #B3BDC8;
  vertical-align: 0 !important;
}

.dropdown {
  .dropdown-toggle::after {
    content: "\e842" !important;
    transition: 0.15s all;
  }

  .dropdown-toggle.btn-outline-danger {
    &::after {
      color: theme-color('danger');
    }

    &:hover::after {
      color: #ffffff;
    }
  }

  .dropdown-toggle.btn-link {
    text-decoration: none !important;
  }

  .dropdown-toggle.arrow-right {
    display: flex;
    align-items: center;

    &>* {
      flex: 1;
    }
  }

  .dropdown-toggle.arrow-right::after {
    top: -1px;
  }

  &.show .dropdown-toggle::after {
    content: "\e845" !important;
  }
}

.dropdown-toggle.btn-primary::after {
  color: #ffffff;
}

.dropup {
  .dropdown-toggle::after {
    content: "\e845" !important;
  }

  &.show .dropdown-toggle::after {
    content: "\e842" !important;
  }
}

.close {
  * {
    display: none;
  }

  &::after {
    content: "\e8f6" !important;
    font-family: 'feather', sans-serif;
  }
}

.btn-outline-light:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-secondary:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}