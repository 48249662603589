@import 'src/styles/colors';

:local(.capitalizeMobilePlaceholder) {
  @media (max-width: 991.98px) {
    height: 60px !important;
    text-align: center;
    border: 0 !important;

    &:focus {
      margin: 3px !important;
      width: calc(100% - 6px) !important;
      height: 54px !important;
    }

    &::placeholder {
      text-transform: uppercase;
    }

    &::-webkit-input-placeholder {
      text-transform: uppercase;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      text-transform: uppercase;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      text-transform: uppercase;
    }

    &:-ms-input-placeholder {
      text-transform: uppercase;
    }
  }
}

.clear {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}