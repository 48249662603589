@import "src/styles/colors";

:local(.container) {
  padding: 80px 0 50px 0;
  overflow: hidden;
  min-height: 300px;

  &.compact {
    min-height: unset;
    height: 100%;
  }

  &:local(.square) {
    padding: calc(50% - 101px) 0;

    &:local(.loading) {
      padding: calc(50% - 25px) 0;
    }
  }

  &:not(:local(.square)) {
    padding: calc(28.125% - 101px) 0;
  }

  &>*:not(:local(.image)) {
    z-index: 100;
  }

  .actionButtonsWrapper {
    position: absolute;
    top: 6px;
    right: 6px;

    display: flex;
    gap: 6px;
  }

  :local(.image) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}