@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
    display: flex;
    width: 100%;
    padding: 0 32px 32px 32px;
}


@include media-breakpoint-down("sm") {
    .container {
        padding: 0 12px 60px 12px;
    }
}