@import "src/styles/colors";

:local(.container) {
  display: block;
  width: 48px;
  height: 28px;
  border: 1px solid #E5E5EA;
  background-color: #FFFFFF;
  padding: 0;
  border-radius: 14px;
  transition: 0.15s all;

  &:focus {
    outline: none;
  }

  &:local(.checked) {
    background-color: theme-color();
    border-color: theme-color();

    :local(.knob) {
      transform: translateX(21px);
    }
  }

  :local(.knob) {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05), 0 2px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    transform: translateX(0);
    transition: 0.15s all;
  }
}