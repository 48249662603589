@import "src/styles/colors";

.wrapper {
  position: relative;
  flex: 1;

  .button {
    position: relative;
    text-align: left;

    &.withIcon {
      display: flex;
      justify-content: space-between;
    }

    .buttonText {
      max-width: 100%;
      overflow-x: hidden;

      display: inline-block;
    }

    .icon {
      position: absolute;
      right: calc(0.375em + 0.1875rem);
      top: 50%;
      transform: translateY(-50%);

      width: calc(0.75em + 0.375rem);
      height: calc(0.75em + 0.375rem);

      color: $gray-600;
    }
  }

  .select {
    position: absolute;
    z-index: 900;
    border-radius: 4px;
    box-shadow: 0 10px 40px 0 rgba(51, 62, 73, 0.15);
    width: 325px;

    &.bottom {
      top: calc(100% + 10px);
    }

    &.top {
      bottom: calc(100% + 10px);
    }

    &.left {
      right: 0;
    }

    .section {
      padding: 26px 53px;
    }

    .chevronButton {
      color: theme-color("darker-light");

      &:disabled {
        color: theme-color("very-light");
      }
    }

    .value,
    .colon {
      font-size: 30px;
      transition: 0.15s color;
    }

    .value:hover {
      color: #FFC100;
    }

    .valueButton {
      padding: 6px 12px;

      &:not(:disabled):hover {
        background-color: #FFC100;
        color: #FFFFFF !important;
        border-color: #FFC100 !important;
      }

      &:disabled {
        background-color: theme-color("darker-light");
        border-color: theme-color("darker-light");
        color: #FFFFFF !important;
        opacity: 0.5;
      }
    }
  }
}