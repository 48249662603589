@import "src/styles/colors";

.container {
  background-color: $red-light;
}

.msp {
  position: relative;
}

.attentionIcon {
  z-index: 1;
  position: absolute;
  top: -10px;
  right: -10px;
}