:local(.recurrenceIcon) {
  right: 10px;
}

:local(.wordBreak) {
  word-break: break-word !important;
  white-space: pre-wrap;
}

:local(.confirmationModalText) {
  white-space: break-spaces;
}

:local(.confirmationModalRadio) {
  flex-shrink: 0;
}