@import "../../../../../../styles/app";

:local(.header) {
  display: flex;
  flex-direction: row;
}

:local(.table) {
  td {
    border-color: $gray-400;
  }
}

:local(.divider) {
  width: 1px;
  background: $gray-400;
}