.activeX > rect,
.nonactiveX > rect {
    transition: all 0.5s ease;
}

//x-style

.activeX > rect:nth-child(2) {
    width: 0;
    opacity: 0;
    transform:  translateX(20px)
}
  
.activeX > rect:first-of-type {
    transform: rotate(45deg) translateY(-13px) translateX(15px);
    transform-origin: top left;
}
  
.activeX > rect:last-of-type {
    transform: rotate(-45deg) translateY(22px) translateX(13px) ;
    transform-origin: bottom left;

}


button {
    background-color: transparent;
    outline: none;
    border: none;

    :focus {
        outline: none;
        border: none;
    }
}
  