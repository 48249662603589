@import "src/styles/colors";

:local(.dialog) {
  margin: auto;
  width: fit-content !important;
}

:local(.formGroup) {
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

:local(.btnsGroupForm) {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px !important;
}

.periodBtn {
  padding: 6px 18px !important;
  width: fit-content !important;
  flex: none !important;

  &:first-child {
    border-radius: 8px;
  }

  &:last-child {
    border-radius: 8px;
  }
}

:local(.dateInputForm) {
  margin-bottom: 24px !important;
  padding-left: 0 !important;

  &:last-child {
    padding-right: 0px !important;
  }
}

:local(.dateInput) {
  border-radius: 8px 0 0 8px !important;
}

:local(.calendar) {
  border-radius: 0 8px 8px 0 !important;
  color: $gray-500 !important;
}

:local(.checkBoxLabel) {
  margin: 0 !important;
  font-weight: 600 !important;
}

:local(.dialogFooter) {
  padding: 28px !important;
}

:local(.cancelButton) {
  margin-right: 28px !important;
}