@import "src/styles/colors";

.passwordCheckBlock {
  margin-top: 6px;
  
  display: flex;
  gap: 24px;

  .ruleColumn {
    .rule {
      display: flex;
      align-items: center;
      gap: 2px;

      font-size: 12px;
      font-weight: 600;
      color: $gray-600;

      &:not(:first-child) {
        margin-top: 4px;
      }

      &.isValid {
        color: $green;
      }
      &.isInvalid {
        color: $red;
      }
    }
  }
}
