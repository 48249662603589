@import 'src/styles/colors';

:local(.copyGrayField) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $gray-600;
  white-space: nowrap;

  strong {
    margin-right: 4px;
    font-weight: 700;
  }

  .copyButton {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    padding: 0;
    outline: none;
    cursor: pointer;

    color: $gray-600;

    &:hover {
      color: $light-black;
    }

    &:disabled {
      color: $gray-600;
      cursor: not-allowed;
    }
  }
}

:local(.startDateCell) {
  font-weight: 700;
  color: $black;
}

:local(.priceCell) {
  font-weight: 700;
  color: $black;
}

:local(.statusButton) {
  margin: 0;
  padding: 0;
  font-weight: 600;
  white-space: nowrap;
}

:local(.paid) {
  color: $green !important;
}

:local(.unpaid) {
  color: $red !important;
}

.manual {
  color: $gray-600 !important;
}

:local(.pending),
:local(.paused),
:local(.pending2ndRun) {
  color: $blue !important;
}