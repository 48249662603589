:local(.phoneInput) {
  border-right: none !important;
  border-radius: 0 !important;
}

:local(.append) {
  border-radius: 0 8px 8px 0 !important;

  &.invalid {
    border-color: #FF0000;
  }
}


:local(.phoneIcon) {
  color: #B3BDC8;

  &.invalid {
    color: #FF0000;
  }
}
