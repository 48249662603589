@import "src/styles/colors";

.divider {
    height: 100%;
    width: 0;
    border-left: 1px solid $gray-400;

    &.horisontal {
        height: unset;
        width: 100%;
        border-left: unset;
        border-top: 1px solid $gray-400;
    }
}

.padding0 {
    margin: 0px;
}

.padding4 {
    margin: 0 4px 0 4px;

    &.horisontal {
        margin: 4px 0 4px 0;
    }
}

.padding8 {
    margin: 0 8px 0 8px;

    &.horisontal {
        margin: 8px 0 8px 0;
    }
}

.padding12 {
    margin: 0 12px 0 12px;

    &.horisontal {
        margin: 12px 0 12px 0;
    }
}

.padding16 {
    margin: 0 16px 0 16px;

    &.horisontal {
        margin: 16px 0 16px 0;
    }
}

.padding24 {
    margin: 0 24px 0 24px;

    &.horisontal {
        margin: 24px 0 24px 0;
    }
}

.padding32 {
    margin: 0 32px 0 32px;

    &.horisontal {
        margin: 32px 0 32px 0;
    }
}