.label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    margin: 0 !important;
}

.cornerRightLine {
    position: absolute;
    left: 10px;
    top: -23px;
}