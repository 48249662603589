@import 'src/styles/colors';

:local(.bodyRow) {
  border-bottom: 1px solid $gray-400;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;

  &:nth-child(odd) {
    background-color: $gray-600-005;
  }

  &:last-child {
    border-bottom: none;
  }

}

.interactive {
  cursor: pointer;

  &:hover {
    background-color: $gray-600-015;
  }
}