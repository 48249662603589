@import '../../../styles/colors';

:local(.client),
:local(.services),
:local(.bundle) {
  color: $black;
}

:local(.services) {
  margin: 0;
  max-width: 200px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

:local(.ended *) {
  color: $gray-500;
}

:local(.paid) {
  color: $green !important;
}

:local(.unpaid) {
  color: $red !important;
}