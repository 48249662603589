@import 'src/styles/colors';

:local(.grayField) {
    color: $gray-600;
}

:local(.filtersContainer) {
    margin-left: 0 !important;
    margin-right: 16px !important;
    width: 400px;
    align-items: flex-start !important;

    &:last-child {
        margin-right: 0 !important;
    }
}

:local(.filter) {
    width: 100%;
    border-radius: 8px !important;
}

:local(.visible) {
    display: block !important;
  }
