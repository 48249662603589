@import "src/styles/colors";

.customLayout {
    background: #FFF;
    gap: 15px;
    padding: 15px;
    width: 392px !important;
    height: 368px !important;

    :global(.react-colorful__saturation) {
        border-bottom: none;
    }

    :global(.react-colorful__hue) {
        border-radius: 0;
        height: 14px;
    }

    :global(.react-colorful__hue-pointer) {
        border-radius: 4px;
        width: 14px;
        height: 22px;
        border: 3px solid #FFF;
        box-shadow: 0px 10px 40px 0px rgba(51, 62, 73, 0.1);
    }

    :global(.react-colorful__saturation) {
        border-radius: 0;
    }

    :global(.react-colorful__saturation-pointer) {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 3px solid #FFF;
        box-shadow: 0px 10px 40px 0px rgba(51, 62, 73, 0.1);
    }
}

.picker {
    position: relative;
}

.swatch {
    max-width: 220px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-left: 20px;
}

.popover {
    z-index: 1000;
    position: absolute;
    bottom: calc(100% + 4px);
    left: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}