@import "src/styles/colors";

.button {
    cursor: pointer;
    background: linear-gradient(#0000, rgba(0, 0, 0, 0.1)) top/100% 800%;
    transition: 0.5s;
}

.button:active {
    background-position: bottom;
}

@media(hover: hover) and (pointer: fine) {
    .button:hover {
        background-position: bottom;
    }
}

.greenField {
    color: $green;
    background-color: $green-010;
}

.grayField {
    color: $black;
    background-color: $gray-600-015;
}

.lightGrayField {
    color: $gray-600;
    background-color: $gray-600-015;
}

.redField {
    color: $red;
    background-color: $red-010;
}

.yellowField {
    color: $white;
    background-color: $primary;
}

.lightYellowField {
    color: $yellow-100;
    background-color: $yellow-020;
}

.badge {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    border-radius: 32px;
    text-wrap: nowrap;
}

.capitalize {
    text-transform: capitalize;
}

.regularField.regularField {
    padding: 12px 16px !important;
    font-size: 16px;
}

.smallField.smallField {
    padding: 8px 12px !important;
    font-size: 14px;
}

.extraSmallField.extraSmallField {
    padding: 4px 8px !important;
    font-size: 12px;
    line-height: 16px;
}

.blackField {
    color: $white;
    background-color: $light-black;
}

.blueField {
    color: $blue;
    background-color: $blue-010;
}

.primaryField {
    color: $primary;
    background-color: $primary-light;
    font-weight: 600;
}

.outlineField {
    color: $black !important;
    border: 1px solid $gray-400;
    background-color: transparent !important;
}