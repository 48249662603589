@import "src/styles/colors";

:local(.profileWalletContainer) {
    margin: 32px 0;
    padding: 0 56px !important;
    border-right: 1px solid $gray-200;
    min-width: 320px;
}

:local(.profileWalletLine) {
    margin-bottom: 24px;
    margin-left: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

:local(.lineName) {
    color: $gray-600;
    padding: 0 !important;
    flex-grow: 8 !important;
}

:local(.lineContent) {
    color: $black;
    font-weight: 600;
    padding: 0 !important;
    max-width: 500px;
    display: flex !important;
    justify-content: center !important;
}

:local(.wideContent) {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:local(.note) {
    justify-content: start !important;
    word-break: break-word;
    white-space: pre-wrap;
}

;