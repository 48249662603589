@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

@include media-breakpoint-up("lg") {
  :local(.image) {
    display: block;
    width: 180px;
    height: 105px;
    object-fit: cover;
  }
}

:local(.participants) {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;

  :local(.participant) {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;

    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      background-color: $gray-500;
    }

    &:local(.paid)::after {
      background-color: $green;
    }

    &:local(.inProgress)::after {
      background-color: $blue;
    }

    &:local(.notPaid)::after {
      background-color: $red;
    }

    &:local(.notPresent)::after {
      background-color: $black;
    }
  }
}