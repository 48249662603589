.header {
    box-shadow: none !important;
    border-bottom: none !important;

    font-size: 24px;
    font-weight: 600;
    line-height: 1.30;
}

.body {
    margin: 24px 0;
    min-height: 72px;
    padding: 0 30px !important;

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    white-space: break-spaces;
}

.footer {
    .actionButton {
        height: 40px;
        border-radius: 4px !important;
        padding: 0 30px !important;
    }
}