@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

:local(.noOutline) {
  button:focus {
    outline: none !important;
  }
}

:local(.helpQuestionIcon) {
  width: 32px;
  height: 32px;
}