@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.searchInput {
  border-radius: 4px;
  height: 40px !important;
  width: 300px;
}

.searchBtn {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  @include media-breakpoint-down("md") {
    display: none !important;
  }
}

.clear {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}