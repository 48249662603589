@import "src/styles/colors";

:local(.profileWalletContainer) {
    margin: 32px 0;
    padding: 0 56px !important;
    border-right: 1px solid $gray-200;
    min-width: 320px;
}

:local(.profileWalletLine) {
    margin-bottom: 24px;
}

:local(.lineName) {
    color: $gray-600;
    padding: 0 !important;
    flex-grow: 8 !important;
}

:local(.lineContent) {
    color: $black;
    font-weight: 600;
    padding: 0 !important;
    margin-left: 15px;
    display: flex !important;
    justify-content: center !important;
}

:local(.inputField) {
    height: 40px !important;
    border-radius: 8px !important;
    border: 1px solid #D8E1EA !important;
    cursor: pointer;
}

:local(.note) {
    justify-content: start !important;
    word-break: break-word;
    white-space: pre-wrap;
}