@import "src/styles/colors";

.loader {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.container {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}