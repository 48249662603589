@import "src/styles/colors";

:local(.row) {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

:local(.field) {
  color: $black;
  font-weight: 700;
}

:local(.value) {
  max-width: 100%;
  color: $gray-600;
}

:local(.currencyIcon) {
  width: 14px;
  height: 14px;
  color: $gray-600;
}

:local(.bundle),
:local(.services) {
  color: $black;
}

:local(.description) {
  word-wrap: break-word;
}

:local(.truncate) {
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
