@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.labelWithInfoIconTooltipRoot {
  width: max-content !important;
  max-width: 400px !important;

  div {
    max-width: 100%;

    .labelWithInfoIconTooltip {
      text-align: left;
      white-space: break-spaces;
    }
  }

  @include media-breakpoint-down("xs") {
    width: 200px !important;
  }
}