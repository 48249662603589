@import 'src/styles/colors';

.emptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.text {
    color: $gray-600;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
}
