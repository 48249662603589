@import "src/styles/colors";

.button {
    outline: none !important;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: linear-gradient(#0000, rgba(0, 0, 0, 0.1)) top/100% 800%;
    transition: 0.5s;
}

.group {
    &:not(&:first-child) {
        border-left: 0 !important;
    }

    &:not(&:only-child) {
        border-radius: 0 !important;


        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }
}


.button:active {
    background-position: bottom;
}

@media(hover: hover) and (pointer: fine) {
    .button:hover {
        background-position: bottom;
    }
}

.size19 {
    border-radius: 4px !important;
    height: 19px;
    width: 19px;
    padding: 2px;
}

.size24 {
    border-radius: 4px !important;
    height: 24px;
    width: 24px;
    padding: 4px;
}

.size32 {
    border-radius: 4px !important;
    height: 32px;
    width: 32px;
    padding: 4px;
}

.size36 {
    border-radius: 4px !important;
    height: 36px;
    width: 36px;
    padding: 6px;
}

.size42 {
    border-radius: 4px !important;
    height: 42px;
    width: 42px;
    padding: 8px;
}

.size40 {
    border-radius: 4px !important;
    height: 40px;
    width: 40px;
    padding: 6px;
}

.size60 {
    border-radius: 0px !important;
    height: 60px;
    width: 60px;
    padding: 4px;
}

.link {
    text-decoration: none !important;
}

.yellow {
    background-color: $yellow !important;
    color: $white !important;
}

.black {
    background-color: $black !important;
    color: $white !important;
}

.gray {
    color: $black !important;
    background-color: $gray-600-015 !important;
}

.white {
    color: $black !important;
    background-color: $white !important;
}

.outline {
    color: $black !important;
    border: 1px solid $gray-400;
    background-color: $white !important;
}

.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
    cursor: default !important;
}

.red {
    background-color: $red !important;
    color: $white !important;
}

.outlineRed {
    color: $red !important;
    border: 1px solid $red;
    background-color: $white !important;
}

.transparent {
    background-color: transparent;
    color: $black !important;
}

.stretched {
    width: 100%;
    height: 100%;
}