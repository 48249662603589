.header {
    box-shadow: none !important;
    border-bottom: none !important;
}

.body {
    padding-top: 0 !important;
}

.footer {
    border-top: none !important;
}