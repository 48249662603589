.container {
    display: flex;
    flex-direction: column;
}

.category {
    max-height: 0;
    transition: all 0.15s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 32px;
    opacity: 0;

    &.show {
        opacity: 1;
        overflow: visible;
        max-height: 112px;
        transition: all 0.25s ease-in;
    }
}