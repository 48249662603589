@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.spacer {
  @include media-breakpoint-down("md") {
    min-height: 60px;
  }
}

:local(.container) {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  box-shadow: 0 -3px 10px 0 rgba(51, 62, 73, 0.1);
  z-index: 980;

  @include media-breakpoint-down("md") {
    min-height: 60px;
  }
}