@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.filtrationButtonsContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
}

:local(.invoiceInfoPanel) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $gray-200;
  box-shadow: 0px 6px 20px rgba(51, 62, 73, 0.06);

  .cellInvoiceInfoPanel {
    margin: 40px;
    width: 239px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $gray-600;
    word-break: break-all;

    @media (max-width: 767px) {
      width: 100%;
      padding: 24px;
    }

    &:first-child {
      margin-left: 60px;

      @media (max-width: 767px) {
        margin-left: 24px;
        align-items: flex-start;
      }
    }

    .cellInvoiceInfoPanelIcon {
      width: 68px;
      height: 70px;
      color: $primary;
    }

    .labelBeforeValue {
      width: 45px;
    }

    .totalPriceText {
      font-size: 32px;
      font-weight: 700;
      line-height: normal;
      color: $light-black
    }

    .categoryPriceText {
      margin-bottom: 9px;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      color: $light-black
    }

    .regularPriceText {
      color: $light-black
    }
  }

  .divider {
    margin: 0 80px;
    width: 1px;
    background-color: $gray-200;

    @media (max-width: 767px) {
      margin: 8px 0;
      width: 100%;
      height: 1px;
    }
  }
}

:local(.mobileButtonsContainer) {
  @include media-breakpoint-up("lg") {
    display: none !important;
  }

  .footerDivider {
    width: 1px;
    height: 60px;
    background: $gray-400;
  }

  .footerIcon {
    width: 60px;
    height: 60px;
    border: 0;
    border-radius: 0;
    padding: 0;
    box-shadow: none !important;

    color: $black !important;
  }
}