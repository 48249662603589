@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.spacer {
    height: 81px;
}

.actionsButtonsWrapper {
    position: fixed;
    background-color: $white;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 40px;
    border-top: 1px solid $gray-200;

    display: flex;
    justify-content: space-between;
    gap: 20px;
}