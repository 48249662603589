@import "colors.scss";
@import "~bootstrap/scss/mixins/breakpoints";

$navbar-light-color: #000;
$navbar-nav-link-padding-x: 0rem;
$navbar-light-toggler-border-color: transparent;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

.navbar-nav,
.nav {
  column-gap: 32px;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar-toggler {
  padding: 0 !important;
  border: none !important;
}

.navbar-nav>.nav-link {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 0;
  padding: 0;
  border-bottom: 3px solid transparent;

  &:hover,
  &:active {
    color: #000 !important;
    border-bottom: 3px solid $gray-400;
  }

  &.active {
    border-color: theme-color();
    color: theme-color() !important;
  }
}

@include media-breakpoint-down("md") {
  .navbar-nav>.nav-link {
    margin: 0;
  }
}

@include media-breakpoint-down("sm") {
  .navbar-nav>.nav-link {
    padding: 1rem;
  }

  .navbar-light .navbar-nav .show>.nav-link,
  .navbar-light .navbar-nav .active>.nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    border-left: 3px solid theme-color();
    padding: 1rem 0.5rem;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 1rem calc(0.5rem + 3px);
  }
}

@include media-breakpoint-up("md") {

  .navbar-light .navbar-nav .show>.nav-link,
  .navbar-light .navbar-nav .active>.nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    border-bottom: 3px solid theme-color();
    padding: 0;
  }
}