@import "src/styles/app";

.container {
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  padding: 0;
  max-width: 100%;

  @include media-breakpoint-down("lg") {
    & {
      grid-template-columns: 100%;
    }

    @for $i from 1 through 7 {
      .head:nth-child(#{$i}) {
        grid-row: $i * 2 - 1;
      }
    }
  }

  .head {
    padding: 12px 20px;
    background-color: rgba(216, 225, 234, 0.17);
    font-weight: 600;
    font-size: 14px;
  }
}