:local(.bookingsList) {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

:local(.bookingsListTitle) {
  margin-bottom: 16px;
}
