@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.locationsContainer {
    width: 100%;
    max-width: 760px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .locationCard {
        border-radius: 4px;
        border: 1px solid $gray-400;
        padding: 24px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        text-decoration: none;

        &:hover {
            background-color: $gray-600-005;

            .locationCardIcon {
                color: $light-black;
            }
        }

        .locationCardInfo {
            margin: 0;
            word-break: break-all;

            .locationCardInfoTitle {
                margin: 0;

                font-size: 20px;
                font-weight: 600;
                line-height: 1.3;
            }

            .locationCardInfoAddress {
                margin: 0;
                margin-top: 4px;

                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
                color: $gray-600;
            }
        }
    }

    .emptyListContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-600;
    }
}