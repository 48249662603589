@import 'src/styles/colors';

:local(.productsGroup) {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
  margin: 0 -48px;

  background-color: $gray-100;

  @media(max-width: 991.98px) {
    margin: 0;
  }
}
