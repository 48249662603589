@import 'src/styles/colors';

:local(.greenField) {
  color: $green;
}

:local(.redField) {
  color: $red;
}

:local(.centeredContent) {
  padding-right: 16px;
  justify-content: center !important;
}

:local(.cell) {
  max-width: 400px;
  color: #818E9C;
}

:local(.checkBox) {
  pointer-events: auto;
}

:local(.avatarCell) {
  width: 56px;
}

:local(.nameCell) {
  min-width: 200px;
  color: #000000;
}

:local(.shrinkable) {
  text-overflow: ellipsis;
  overflow: hidden;
}

:local(.infoWithIcon) {
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

:local(.icon) {
  margin-right: 8px;
  flex-shrink: 0;
}

:local(.emailCell) {
  min-width: 200px;
}

:local(.addressCell) {
  min-width: 150px;
}