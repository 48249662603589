@import 'src/styles/colors';
@import 'src/styles/form';

.numberInputContainer {
  height: 38px;
  border: 1px solid $gray-400;
  border-radius: 4px;
  overflow: hidden;

  &:focus-within {
    box-shadow: $input-focus-box-shadow;
    border-color: $input-focus-border-color;
  }

  &.invalid {
    border-color: $input-focus-border-color-invalid;

    &:focus-within {
      box-shadow: $input-focus-box-shadow-invalid;
    }
  }

  .numberInputInput {
    border: none;
    border-radius: 0;
    box-shadow: none !important;
  }

  .prependInput {
    border: none;
    border-radius: 0;
    box-shadow: none !important;

    background-color: $gray-200;
  }

  .numberInputButtons {
    margin: 0;
    height: 100%;
    border-left: 1px solid $gray-400;

    display: flex;
    flex-direction: column;

    .numberInputButtonsItem {
      height: 50%;
      padding: 0;
      outline: none;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $gray-500;

      &:first-child {
        border-bottom: 1px solid $gray-400;
      }

      &:disabled {
        color: $gray-200;
      }

      .numberInputButtonsItemIcon {
        width: 60%;
        height: auto;
      }
    }
  }
}