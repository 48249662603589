@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

@import "colors";

.border-dashed {
  border: 1px dashed transparent !important;
}

$borderSides: (
        "top",
        "right",
        "bottom",
        "left"
);

@each $name, $value in $grid-breakpoints {
  @each $side in $borderSides {
    @include media-breakpoint-up($name) {
      .border-#{$name}-#{$side} {
        border-#{$side}: 1px solid $gray-300;
      }
    }
  }
}