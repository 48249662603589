@import "src/styles/colors";

.typography {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    position: relative;
    margin: 0;

    font-family: Mulish;
    text-align: left;

    div {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.yellow {
    color: $yellow !important;
}

.black {
    color: $black !important;
}

.gray {
    color: $gray-600 !important;
}

.red {
    color: $red !important;
}

.green {
    color: $green !important;
}

.blue {
    color: $blue !important;
}

.uppercase {
    text-transform: uppercase;
}

.ellipsis {
    display: inline-block;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.pointer {
    cursor: pointer;
}

.noWrap {
    white-space: nowrap;
}

.bold {
    font-weight: 700 !important;
}

.normal {
    font-weight: 600 !important;
}

.light {
    font-weight: 400 !important;
}

.italic {
    font-style: italic;
}

.lineThrough {
    text-decoration: line-through;
}

.capitalize {
    text-transform: lowercase;
    display: inline-block;
}

.capitalize::first-letter {
    text-transform: uppercase;
}

.breakWord {
    word-break: break-word;
}