@import "src/styles/colors";

.label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    gap: 4px;
}

.paddingBottom {
    padding-bottom: 8px;
}

.extraPaddingBottom {
    padding-bottom: 12px;
}