@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
  display: flex;
  align-items: flex-start;
  gap: 60px;
  flex: 1;

  .navigation {
    width: fit-content;
    box-sizing: border-box;
    padding-left: 40px;
  }

  .content {
    width: 100%;
  }
}

@include media-breakpoint-down("lg") {
  .container {
    flex-direction: column;
    gap: 24px;

    .navigation {
      width: 100%;
      padding: 0;
    }

    .content {
      padding: 0 24px;
    }
  }
}