@import "colors";

$input-focus-box-shadow: append(0 0 0 1px, transparentize(theme-color(), 0.5));
$input-focus-box-shadow-invalid: append(0 0 0 1px, transparentize(theme-color('danger'), 0.75));
$input-focus-border-color: theme-color();
$input-focus-border-color-invalid: theme-color('danger');
$dropdown-link-active-bg: theme-color();
$input-group-addon-bg: #fff;

.form-label {
  font-weight: bold;
}

.form-check-label {
  width: fit-content;
}

.form-control {
  display: flex !important;
  height: calc(1.5em + 0.875rem + 2px);
  padding: 0.4375rem 0.75rem;

  &:disabled,
  &[readonly] {
    border-color: $gray-500;
  }

  &:focus,
  &:active {
    box-shadow: $input-focus-box-shadow;
  }
}

.form-check {
  display: flex !important;
  align-items: center;

  input[type="checkbox"] {
    position: relative;
    top: -3px;
  }

  label {
    position: relative;
    font-size: 14px;
    font-weight: bold;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #D8E1EA;
  border-radius: 4px;
  display: inline-block;
  min-width: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  position: relative;
  margin-right: 8px;
  transition: 0.15s all;

  &,
  &+label {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:checked {
    border-color: theme-color();
    background-color: theme-color();

    &:disabled {
      border-color: grayscale($primary);
      background-color: grayscale($primary);
    }

    &::after {
      content: "\e83f";
      font-family: 'feather', sans-serif;
      speak: none;
      font-style: normal;
      font-weight: bolder;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #FFFFFF;
    }
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #D8E1EA;
  border-radius: 50%;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  position: relative;
  margin-top: 0;
  margin-right: 8px;
  transition: 0.15s all;

  &,
  &+label {
    cursor: pointer;
    top: 1px;
  }

  &:focus {
    outline-color: transparent;
  }

  &:checked {
    border-color: theme-color();

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: theme-color();
      border-radius: 50%;
    }

    &:disabled {
      border-color: grayscale($primary);

      &::after {
        background-color: grayscale($primary);
      }
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}

.input-group {
  .form-control {
    border-right: 0;
    height: calc(1.5em + 0.75rem + 4px);

    &.default-borders {
      border-right: 1px solid #D8E1EA !important;
      border-top-right-radius: .25rem !important;
      border-bottom-right-radius: .25rem !important;
    }
  }

  .input-group-prepend.dropdown .dropdown-toggle {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}