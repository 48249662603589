@import 'src/styles/colors';

:local(.iconButton) {
    &:focus {
        outline: none;
    }
}

:local(.bookingServiceName) {
    display: flex;
    align-items: center;

    :local(.reloadIconWrapper) {
        margin-right: 6px;
        width: 16px;
        height: 16px;

        display: flex;

        cursor: pointer;
    }
}

:local(.greenField) {
    color: $green;
}

:local(.redField) {
    color: $red;
}

:local(.grayField) {
    color: $gray-600;
}

:local(.blueField) {
    color: $blue;
}

:local(.capitalize) {
    text-transform: capitalize;
}