.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actions {
  display: flex;
  justify-content: space-between;
}