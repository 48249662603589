@import 'src/styles/colors';

:local(.wrapper) {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $black;
  }
}

.name {
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 600;
  color: $gray-600;
  white-space: nowrap;
}

.iconSort {
  color: var(--darker-light);
  min-width: fit-content;
}

.active {
  color: var(--darker-gray)
}

.left {
  text-align: left;
  justify-content: flex-start;
}

.right {
  text-align: right;
  justify-content: flex-end;
}

.center {
  text-align: center;
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
  white-space: wrap;
}