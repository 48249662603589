@import 'src/styles/colors';

:local(.discountName) {
    color: $gray-700;
    margin-right: 6px !important;
}

:local(.discountValue) {
    font-weight: 700;
    color: $black;
}
