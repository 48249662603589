:local(.filtersContainer) {
    margin-left: 0 !important;
    margin-right: 16px !important;
    width: 400px;
    align-items: flex-start !important;

    &:last-child {
        margin-right: 0 !important;
    }

    @media (max-width: 992px) {
        width: 280px;
        margin-bottom: 10px;
    }
}

:local(.filter) {
    width: 100%;
    border-radius: 8px !important;
}