@import 'src/styles/colors';

:local(.footerRow) {
  border-bottom: 1px solid $gray-400;
  color: $gray-600;
  font-size: 14px;


  &:last-child {
    border-bottom: none;
  }
}

:local(.footerCell) {
  border: none !important;
}
