@import 'src/styles/colors';

:local(.emptyTableContainer) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

:local(.text) {
  color: $gray-600;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
}