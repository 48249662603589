@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
  display: flex;
  gap: 80px;
  max-width: 100%;
  width: 100%;
}

.createAndFilter {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 30%;
  max-width: 100%;
}

.items {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;

  @include media-breakpoint-up("lg") {
    width: calc(70% - 80px);
  }

  @include media-breakpoint-down("xs") {
    gap: 32px;
  }
}