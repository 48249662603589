@import "src/styles/colors";


.sentToCell {
    display: flex;
}

.grayColor {
    color: $gray-600;
}

.greenColor {
    color: $green;
}