@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

:local(.mobileButtonsContainer) {
  @include media-breakpoint-up("lg") {
    display: none !important;
  }

  height: 60px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .actions {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    flex: 1;

    gap: 16px;
  }

  .footerDivider {
    width: 1px;
    height: 60px;
    background: $gray-400;
  }
}