@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

:local(.modalHeader) {
  & button {
    opacity: 1;

    &:hover {
      opacity: 0.5 !important;
    }
  }
}

:local(.calendarIcon) {
  color: $gray-600;
}

.clientHeaderWrapper {
  margin-bottom: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clientTitle {
  font-size: 22px;
  font-weight: 700;
}