@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.image {
  width: 320px;
  height: 210px;
  border-radius: 4px;
  overflow: hidden;
}

.rowContent {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  flex: 1;
}

.qrCode {
  border-radius: 4px;
  border: 1px solid $gray-400;
  background: $white;
  width: 116px;
  height: 116px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 16px;
  line-height: 24px;
  color: $gray-600;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: nowrap;
  width: 100%;
}

.actions {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.contentTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contentTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.contentSubTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.link {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;

  border-radius: 4px;
  text-wrap: nowrap;
  border: 1px solid $gray-400;
  background-color: $white;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
}

@include media-breakpoint-down("lg") {

  .image {
    width: 100%;
    height: fit-content;
  }

  .rowContent {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .actions {
    flex-wrap: wrap;
  }

  .contentHeader {
    flex-wrap: wrap;
  }
}