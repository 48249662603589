@import "src/styles/colors";

.greenField {
    color: $green;
    background-color: $green-010;
    .chipDelete {
        color: $gray-100;
    }
    .chipDelete:hover {
        color: $white;
        background-color: $gray-600-010;
    }
}

.grayField {
    color: $light-black;
    background-color: $gray-600-015;
    .chipDelete {
        color: #56627080;
    }
    .chipDelete:hover {
        color: $light-black;
    }
}

.redField {
    color: $red;
    background-color: $red-010;
    .chipDelete {
        color: $gray-100;
    }
    .chipDelete:hover {
        color: $white;
        background-color: $gray-600-010;
    }
}

.yellowField {
    color: $white;
    background-color: $yellow;
    .chipDelete {
        color: $gray-100;
    }
    .chipDelete:hover {
        color: $white;
        background-color: $gray-600-010;
    }
}

.chip {    
    min-width: 0;
    width: fit-content;
    max-width: 100%;
    border-radius: 32px !important;

    outline: none !important;
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    cursor: unset !important;
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button {
    cursor: pointer !important;
}

.chipDelete {
    height: 24px;
    width: 24px;
    padding: 4px;
    border-radius: 32px;
    background-color: $gray-600-015;
    margin-right: -8px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
}

.capitalize {
    text-transform: capitalize;
}

.regularField{
    padding: 12px 16px;
    height: 40px;
}

.smallField {
    padding: 8px 12px;
}
