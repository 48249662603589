.hide {
    display: none;
}

.container {
    background: white;
    width: 1240px;
    height: 1750px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 810px;
    margin-top: 90px;
    gap: 7px;
}

.logo {
    height: 140px;
}

.name {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
}

.qrCodeContainer {
    position: relative;
    width: 810px;
    height: 810px;
    margin-top: 180px;
    padding: 80px;
    border-radius: 40px;
    background: white;
    filter: drop-shadow(0px 22px 90px rgba(51, 62, 73, 0.15));
}

.scanForBooking {
    position: absolute;
    width: max-content;

    bottom: -66px;
    left: 50%;
    transform: translate(-50%, 0);

    border-radius: 20px;
    border: none;
    padding: 24px 48px;

    color: white;
    font-size: 56px;
    font-weight: 700;
    text-align: center;
}

.footer {
    margin-top: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    color: rgba(32, 32, 36, 1);

    font-size: 32px;
    font-weight: 600;
    text-align: center;
}