@font-face {
  font-family: 'feather';
  src: url('../static/fonts/feather.eot');
  src: url('../static/fonts/feather.woff') format('woff'),
  url('../static/fonts/feather.ttf') format('truetype'),
  url('../static/fonts/feather.svg') format('svg');
}

$font-family-base: 'Mulish';

$h1-font-size: 28px;
$h2-font-size: 16px;
$h3-font-size: 20px;
$headings-font-weight: 800;

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.text-reset,
.text-transform-reset {
  text-transform: none;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.text-line-through {
  text-decoration: line-through;
}

.visually-hidden {
  display: none !important;
}