@import "src/styles/colors";

.column {
    display: flex;
    flex-direction: column;
    height: max-content;
    max-height: 100%;
    position: relative;

    max-width: 100%;
}

.padding0 {
    padding: 0 0px;
}

.padding4 {
    padding: 0 4px;
}

.padding8 {
    padding: 0 8px;
}

.padding12 {
    padding: 0 12px;
}

.padding16 {
    padding: 0 16px;
}

.padding32 {
    padding: 0 32px;
}

.gap0 {
    gap: 0px;
}

.gap4 {
    gap: 4px;
}

.gap8 {
    gap: 8px;
}

.gap10 {
    gap: 10px;
}

.gap12 {
    gap: 12px;
}

.gap16 {
    gap: 16px;
}

.gap32 {
    gap: 32px;
}

.stretched {
    width: 100%;
}

.center {
    align-items: center;
}

.start {
    align-items: flex-start;
}

.end {
    align-items: flex-end;
}

.spacing0 {
    margin: 0px;
}

.spacing4 {
    margin: 4px 0px;
}

.spacing8 {
    margin: 8px 0px;
}

.spacing12 {
    margin: 12px 0px;
}

.spacing16 {
    margin: 16px 0px;
}

.spacing32 {
    margin: 32px 0px;
}

.justifyCenter {
    justify-content: center;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyAround {
    justify-content: space-around;
}

.shrink {
    min-width: 0;
}