@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.dialogFooter {
  border: none !important;
  border-radius: 0 0 6px 6px;
  padding: 24px 28px !important;

  @include media-breakpoint-down("md") {
    padding-bottom: 80px !important;
  }
}