@import 'src/styles/colors';

:local(.headerCell) {
  padding: 10px 20px !important;
  border: none !important;
  vertical-align: middle !important;

  &:first-child {
    border-left: none !important;
  }

  &:last-child {
    border-right: none !important;
  }
}