@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
    margin: 0 !important;
    padding: 0 40px !important;
    flex-grow: 2;

    display: flex;

    .form {
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        .contentWrapper {
            margin-bottom: 40px;

            .fileInput {
                font-size: 16px;

                .fileInputSub {
                    display: block;
                    font-size: 14px;
                }
            }

            .dateLabel {
                margin-top: 32px;
                font-size: 14px;
                font-weight: 600;
            }

            .dateCheckbox {
                margin-top: 24px;
                margin-bottom: 0;

                display: flex;
                align-items: center;

                &+.dateCheckbox {
                    margin-top: 12px;
                }

                label {
                    font-weight: 400;
                }
            }

            .infoIcon {
                margin-left: 10px;
                cursor: pointer;
                color: $gray-600;
            }

            .datePickersContainer {
                margin-top: 24px;

                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px;

                .datePicker {
                    margin: 0;
                    min-width: 140px;

                    @media (max-width: 1472px) {
                        width: calc((100% - 12px) / 2);
                    }

                    @media (max-width: 1016px) {
                        width: 100%;
                    }

                    @include media-breakpoint-down("sm") {
                        width: calc((100% - 12px) / 2);
                    }

                    @include media-breakpoint-down("xs") {
                        width: 100%;
                    }
                }

                .hyphen {
                    width: 14px;
                    height: 2px;
                    background: $gray-400;

                    @media (max-width: 1600px) {
                        display: none;
                    }
                }
            }

            .sendToCheckbox {
                margin-top: 16px;
                margin-bottom: 0;

                display: flex;
                align-items: center;

                label {
                    font-weight: 400;
                }
            }
        }

        .actionsButtonsWrapper {
            margin: auto -40px 0;
            padding: 20px 40px;
            border-top: 1px solid $gray-200;

            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }
    }
}

.immediatelyTooltip {
    width: auto !important;

    div {
        max-width: 100%;
    }
}

.indefiniteTooltip {
    width: 244px !important;

    div {
        max-width: 100%;
        text-align: left;
    }
}

.sendAlsoToEmailTooltip {
    width: auto !important;

    div {
        max-width: 100%;
    }
}

.grayColor {
    color: $gray-600;
}

.clientsSelect {
    .sendToLabel {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    div {
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    .option {
        margin: 0;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
    }
}