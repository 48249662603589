@import 'src/styles/colors';

:local(.bookingsList) {
  margin: 0 0 8px;
  padding: 0;
  max-height: 328px;
  font-size: 14px;
  font-style: italic;
  overflow: auto;
}

:local(.booking) {
    position: relative;
    padding-left: 22px;

    &::before {
        display: block;
        content: '';
        width: 12px;
        height: 3px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #D8D8D8;
    }
}
