@import "colors";

@each $name, $color in $theme-colors {
  .toast-#{$name} {
    .toast-header {
      color: color-yiq(darken($color, 15)) !important;
      background-color: darken($color, 15) !important;
      padding: 0.5rem 1rem;

      .close {
        margin-bottom: 0 !important;
        color: color-yiq($name);
        font-size: 1rem;
      }
    }
  }
}