@import "src/styles/colors";

.wrapper {
  position: relative;

  .button {
    position: relative;
    text-align: left;

    &.withIcon {
      display: flex;
      justify-content: space-between;
    }

    .buttonText {
      max-width: 100%;
      overflow-x: hidden;

      display: inline-block;
    }

    .icon {
      position: absolute;
      right: calc(0.375em + 0.1875rem);
      top: 50%;
      transform: translateY(-50%);

      width: calc(0.75em + 0.375rem);
      height: calc(0.75em + 0.375rem);

      color: $gray-600;
    }
  }

  .calendar {
    position: absolute;
    z-index: 900;
    border-radius: 4px;
    box-shadow: 0 10px 40px 0 rgba(51, 62, 73, 0.15);
    width: 300px;

    &.left {
      left: 0;
    }

    &.right {
      right: 0
    }

    &.bottom {
      top: calc(100% + 10px);
    }

    &.top {
      bottom: calc(100% + 10px);
    }
  }
}