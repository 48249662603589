@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
  .column {
    border-right: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;

    &:nth-child(1) {
      border-left: 1px solid $gray-400;
    }

    .weekday {
      position: sticky;
      display: flex;
      justify-content: center;
      font-weight: bold;
      padding: 0.5rem 0;
      z-index: 850;
      background-color: $gray-100;
      border-top: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;

      &.selected {
        background-color: $primary-light;
        border: 1px solid $primary;
        margin: 0 -1px 0 -1px;
      }
    }

    @include media-breakpoint-down("sm") {
      .weekday {
        top: 67px;
      }
    }

    @include media-breakpoint-up("md") {
      .weekday {
        top: 72px;
      }
    }

    @include media-breakpoint-down("md") {
      &:not(:first-child) .weekday {
        border-top: 0 !important;
      }
    }
  }

  @include media-breakpoint-up("lg") {
    .column {
      flex: calc(1 / 7 * 100%);
      width: calc(1 / 7 * 100%);
    }
  }
}