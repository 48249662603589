:local(.bookingsListItem) {
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-weight: 500;
  font-style: italic;

  &::before {
    content: '';
    margin-right: 10px;
    width: 12px;
    height: 3px;
    display: inline-block;
    background-color: #D8D8D8;
  }
}

:local(.objectName) {
  margin-right: 15px;
  max-width: 40%;
  flex-shrink: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:local(.dateInfo) {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  align-self: flex-end;
  color: #818E9C;
}

:local(.clockIcon) {
  margin-right: 8px;
}
