@import "src/styles/colors";

:local(.dialog) {
  width: 1400px !important;
  max-width: 90vw !important;
}

:local(.navBar) {
  &>*:last-child {
    margin-right: 0 !important;
  }

  &>*:first-child {
    margin-left: 0 !important;
  }
}

:local(.tabContent) {
  margin: 0 -28px;
  overflow: auto;
}