@import 'src/styles/colors';

:local(.alignTop) {
    vertical-align: top !important;
}

:local(.iconButton) {
    &:focus {
        outline: none;
    }
}

:local(.grayField) {
    color: $gray-600;
}

:local(.greenField) {
    color: $green;
}

:local(.yellowField) {
    color: $yellow;
}

:local(.redField) {
    color: $red;
}

:local(.comment) {
    width: 550px;

    &.hide {
        max-height: 23px;
        overflow: hidden;
    }
}
