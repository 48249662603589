:local(.formGroup) {
  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

:local(.visible) {
  display: block !important;
}

:local(.note) {
  resize: none;
}

:local(.checkBoxLabel) {
  margin: 0 !important;
  font-weight: 700;
}