@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

:local(.sorting) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-right: 1px solid $gray-400 !important;

  :local(.button) {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      border-bottom: 1px solid $gray-400 !important;
    }

    border: none;
    height: 50%;

    &:focus {
      border: none;
      outline: none;
    }
  }
}