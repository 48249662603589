@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.status {
    width: 100%;
    padding: 16px;
    gap: 10px;
    border-radius: 4px;
}

.green {
    color: $green;
    background-color: $green-010;
}

.red {
    color: $red;
    background-color: $red-010;
}

.yellow {
    color: $white;
    background-color: $primary;
}

.gray {
    color: $black;
    background-color: $gray-600-015;
}