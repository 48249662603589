@import "colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$body-bg: #EEEEEE;

@import "badge";
@import "border";
@import "button";
@import "dropdown";
@import "flex";
@import "form";
@import "image";
@import "modal";
@import "nav";
@import "navbar";
@import "shadow";
@import "text";
@import "toast";
@import "popover";

@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/type";

$spacers: map-merge($spacers,
    (6: $spacer * 3.5,
      8: $spacer * 4.5,
    ));

@import "~bootstrap/scss/utilities";

body {
  overflow-y: scroll;
}

body,
#root,
#root>div,
#root>div>.container,
#root>div>.container-fluid {
  min-height: 100vh;
}

.border-2 {
  border-width: 2px !important;
}

.border-5 {
  border-width: 5px !important;
}

.d-grid {
  display: grid !important;
}

@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .d-#{$name}-grid {
      display: grid !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .w-#{$name}-100 {
      width: 100% !important;
    }
  }
}

.text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-overflow {
  text-overflow: ellipsis;
  min-height: 50px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    /* note: not using bottom */
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

.react-toast-notifications__container {
  z-index: 1051 !important;

  &>div {
    height: fit-content !important;
  }
}

.border-radius-0 {
  border-radius: 0 !important;
}

.form-group {
  width: 100%;
}