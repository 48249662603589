@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
    @include media-breakpoint-down("md") {
        gap: 20px;
        display: flex;
    }
}

.mobile {
    @include media-breakpoint-down("md") {
        gap: 20px;

        :global(.dropdown-toggle) {
            font-size: 20px;
            position: relative;
            display: flex;
            gap: 10px;
            align-items: center;
            color: black !important;
            font-weight: 600 !important;
            padding: 20px 16px 20px 20px !important;

            border-radius: 4px 4px 0px 0px;
            border: 1px solid $gray-400 !important;

            &::after {
                color: initial;
                margin-left: auto;
            }
        }

        :global(.dropdown-item) {
            position: relative;
            display: flex;
            gap: 10px;
            align-items: center;
            padding-top: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
            font-size: 20px;

            &::after {
                position: relative;
                top: 2px;

                font-family: 'feather', sans-serif;
                speak: none;
                font-style: normal;
                font-weight: bolder;
                font-variant: normal;
                text-transform: none;
                font-size: 17px;
                line-height: 1;
                border: 0 !important;
                vertical-align: 0 !important;

                margin-left: auto;

                content: "\e844" !important;
            }
        }

        :global(.dropdown-item.active) {
            color: #FFC100 !important;
        }

        :global(.dropdown-menu.show) {
            padding: 20px 16px 20px 0px !important;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 32px;

            border-radius: 0px 0px 4px 4px;
            border: 1px solid $gray-400 !important;
            border-top: none !important;

            box-shadow: none !important;
        }

        :global(.nav-link:not(.dropdown-toggle)) {
            position: relative;
            display: flex;
            gap: 10px;
            padding: 20px 16px 20px 20px !important;

            border-radius: 4px;
            border: 1px solid $gray-400 !important;
            font-size: 20px;

            &::after {
                position: relative;
                top: 2px;

                font-family: 'feather', sans-serif;
                speak: none;
                font-style: normal;
                font-weight: bolder;
                font-variant: normal;
                text-transform: none;
                font-size: 17px;
                line-height: 1;
                border: 0 !important;
                vertical-align: 0 !important;

                margin-left: auto;

                content: "\e844" !important;
            }
        }
    }
}

.divider {
    margin: 20px 0 !important;
    width: 100%;
}


.profile,
.language,
.logout {
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid $gray-400;
    background: $gray-600-005;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language,
.logout {
    cursor: pointer;
}

.profile {
    padding: 20px 15px;
}

.noPointerEvents {
    pointer-events: none;
}