@import "src/styles/app";

.dateCardListContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;

    .actionButton {
        min-width: 205px;
        padding: 10px 24px;
        flex-shrink: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .actionButtonIcon {
            width: 20px;
            height: 20px;
        }
    }
  }

  .dateCardListItem {
    position: relative;
  }
}

@include media-breakpoint-down("sm") {
  .dateCardListContainer {
    .header {
      align-items: flex-start;

      .actionButton {
          min-width: 0;
          width: 40px;
          height: 40px;
          padding: 10px;

          .actionButtonText {
              display: none;
          }
      }
    }
  }
}