@import "src/styles/colors";

:local(.container) {
  & + :local(.container),
  &:not(:first-child) {
    border-top: 0 !important;
  }

  :local(.booking) {
    box-shadow: 0 2px 10px 0 rgba(51,62,73,0.1);
    transition: 0.15s all;

    &:local(.clickable) {
      cursor: pointer;

      &:hover {
        background-color: $gray-300;
      }
    }
  }
}

:local(.breakable) {
  word-break: break-all;
}

:local(.staticHeight) {
  min-height: 45px;
}
