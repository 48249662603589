@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

:local(.container) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  padding: 0;
  max-width: 100%;
  border-radius: 4px;

  @include media-breakpoint-down("lg") {
    & {
      grid-template-columns: 100%;
    }

    @for $i from 1 through 7 {
      :local(.head):nth-child(#{$i}) {
        grid-row: $i * 2 - 1;
      }
    }
  }

  :local(.head) {
    padding: 11px 21px;
    background-color: rgba(216, 225, 234, 0.17);

    &:local(.selected) {
      background-color: rgba(216, 225, 234, 0.5);
    }
  }
}