:local(.formGroup) {
  padding: 0 8px !important;

  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

:local(.visible) {
  display: block !important;
}

:local(.note) {
  resize: none;
}

:local(.blockButton) {
  margin-right: 16px !important;
  width: 24px !important;
  height: 24px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &.delete {
    color: #FF0000;

    &:hover {
      border-color: #FF0000;
    }
  }
}

:local(.blockIcon) {
  width: 16px;
  height: 16px;
}

:local(.checkBoxLabel) {
  margin: 0 !important;
  font-weight: 700;
}