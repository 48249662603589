@import "src/styles/colors";

:local(.periodPickerContainer) {
  position: relative;
  min-width: 180px;
  height: 40px;

  .periodPickerToggleButton {
    width: 100%;
    height: 100%;
    border: 1px solid $gray-400;
    border-radius: 4px;
    outline: none;
    padding: 11px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    white-space: nowrap;

    &.periodPickerToggleButtonActive {
      border-color: $yellow;
    }

    .periodPickerToggleButtonIcon {
      flex-shrink: 0;
    }
  }

  .panelContainer {
    position: relative;
    z-index: 20;
  }

  .periodPickerDropdown {
    min-width: 370px;
    position: absolute;
    top: calc(100% + 4px);

    padding: 32px;

    border: 1px solid $gray-400;
    background-color: $white;

    display: flex;
    flex-direction: column;
    gap: 24px;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}

.stretched {
  width: 100%;
}