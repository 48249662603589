@import "src/styles/colors";

.avatarContainer {
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
}

.avatar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
}

.empty {
  width: 100%;
  height: 100%;
}

.emptyWithText {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16.55px;
  font-weight: 600;
  line-height: 21.27px;
  text-transform: uppercase;
}

.small {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}

.medium {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

.large {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
}

.link {
  text-decoration: none !important;
}