@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.option {
    margin: 0 !important;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}