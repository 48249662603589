@import 'src/styles/colors';

.clientHeaderWrapper {
    margin-bottom: 18px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.clientTitle {
    font-size: 22px;
    font-weight: 700;
}