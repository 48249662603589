@import "src/styles/app";

.dateCardContainer {
    width: 100%;
    padding: 20px;
    border: 1px solid $gray-400;
    border-radius: 4px;

    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;

    .header {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
        color: $gray-600;
        text-transform: uppercase;
    }

    .label {
        margin-bottom: 12px;

        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }

    .nameGroup {
        margin-top: 16px;
        width: 100%;
    }

    .singleGroupWrapper {
        margin-top: 24px;
        width: calc(((100% - 24px) / 2 - 8px) / 2);
    }

    .multipleGroupWrapper {
        margin-top: 24px;
        width: calc((100% - 24px) / 2);

        display: flex;
        justify-content: space-between;

        &>* {
            width: calc((100% - 8px) / 2);
        }
    }

    .dateInput,
    .timeInput {
        height: 40px;

        &::placeholder {
            color: $gray-600;
        }
    }
}

@include media-breakpoint-down("sm") {
    .dateCardContainer {
        .multipleGroupWrapper {
            width: 100%;
        }
    }
}