@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .messageName {
        padding-right: 10px;
    }

    .clientsSelect {
        margin: 0;

        div {
            &::-webkit-scrollbar {
                width: 0;
            }
        }

        .option {
            margin: 0;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
        }
    }
}

.grayColor {
    color: $gray-600;
}

.yellowColor {
    color: $yellow;
}

.sendAlsoToEmailTooltip {
    width: auto !important;

    div {
        max-width: 100%;
    }
}

.footer {
    display: flex;
    justify-content: space-between !important;
}