@import "src/styles/colors";

:local(.container) {
  position: relative;
  border: 1px solid #D8E1EA;
  border-left: 0;
  border-top: 0;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(51, 62, 73, 0.1);
  z-index: 500;
  transition: 0.15s background-color;

  &:local(.compact) {
    display: flex;
    align-items: center;
  }

  &:local(.clickable) {
    cursor: pointer;

    &:hover {
      background-color: $gray-300;
    }
  }

  &:local(.inProgress)::before,
  &:local(.paid)::before,
  &:local(.notPresent)::before,
  &:local(.paidAfterwards)::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 5px;
    height: 100%;
  }

  &:local(.inProgress)::before {
    background-color: $blue;
    box-shadow: 0 2px 16px 0 transparentize($blue, 0.35);
  }

  &:local(.paid)::before {
    background-color: $green;
    box-shadow: 0 2px 16px 0 transparentize($green, 0.35);
  }

  &:local(.paidAfterwards)::before {
    background-color: $red;
    box-shadow: 0 2px 16px 0 transparentize($red, 0.35);
  }

  &:local(.notPresent)::before {
    background-color: $black;
    box-shadow: 0 2px 16px 0 transparentize($black, 0.35);
  }

  :local(.content) {
    padding: 15px;
  }

  &:local(.compact .content) {
    padding: 5px 15px;
  }

  :local(.extraTimeContainer) {
    width: 100%;
    flex-shrink: 0;

    &:local(.inProgress) {
      background-color: transparentize($blue, 0.85);
    }

    &:local(.paid) {
      background-color: transparentize($green, 0.85);
    }

    &:local(.paidAfterwards) {
      background-color: transparentize($red, 0.85);
    }

    &:local(.notPresent) {
      background-color: transparentize($black, 0.85);
    }
  }
}

:local(.madeFromIconWrapper) {
  margin-top: 2px;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  display: flex;
  flex-shrink: 0;
}

:local(.serviceName) {
  width: calc(100% - 19px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:local(.clientName) {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}