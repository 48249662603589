@import 'src/styles/colors';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

// grid with cards rows, if cards ovwerflow, they will be placed in new row, if big screen four columns but min card width is 370px
.cardsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}