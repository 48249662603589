@import 'src/styles/colors';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    padding: 4px;
}

.image {
    width: calc(20% - 4px);
    aspect-ratio: 16/9;
}

.imageCompact {
    width: calc(50% - 4px);
}

.imagePlaceholder {
    width: 100%;
    height: 220px;
}

.square {
    aspect-ratio: 1;
}

.gray {
    color: $gray-600;
}

.compact {
    width: 100%;
    height: 100%;
    background-color: $gray-600-010;
    cursor: pointer;
}