@import 'src/styles/colors';

:local(.clientCell),
:local(.titleCell),
:local(.statusCell) {
  color: $black;
}

:local(.statusCell) {
  font-weight: 600;
}

:local(.titleStatus) {
  display: block;
}

:local(.pauseStatus) {
  display: block;
  color: $blue;
}

:local(.paid) {
  color: $green !important;
}

:local(.pending) {
  color: $blue !important;
}

:local(.unpaid) {
  color: $red !important;
}

:local(.paused) {
  color: $blue !important;
}

.manual {
  color: $gray-600 !important;
}