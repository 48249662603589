@import 'src/styles/colors';

:local(.formGroupHeading) {
  display: flex;
  padding: 12px 24px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

:local(.datesPeriod) {
  display: flex;
  align-items: center;
  margin-left: 25px;
  color: $gray-700;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 5px;
  }
}

:local(.datePikcerArrow) {
  
  flex-grow: 0;
  padding: 0;
  color: #818E9C;
  background-color: transparent;
  border: 0;

  &:disabled {
    color: #dcdee0;
  }
  &:focus {
    outline: none;
  }
}

:local(.table) {
  margin: 0 16px;

  @media screen and (max-width: 768px) {
      
    border: 0;

      thead {
        display: none;
      }

      tr {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        td:first-child span {
          display: flex;
        }

        td:last-child {
          order: 3;
        }

        td:nth-last-child(2) {
          order: 4;
        }
      }

      td {
        display: block;
        text-align: left;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
        border-right: 1px solid transparent;
        padding: 0px;
        width: 90%;

        span {
          line-height: initial;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:before {
          width: 30%;
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-align: left;
        }

        &:nth-last-child(1):before {
          width: 100%;
        }
      }
  }
  
}

:local(.tdText) {
  display: flex;
  align-self: center;
  line-height: 38px;
}

:local(.monthlyService) {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

:local(.weeklyService) {
  padding: 0 !important;
  border-top: 1px solid $gray-400;
  background-color: rgba(119,119,119,0.03);
  margin-bottom: 0 !important;
}

:local(.serviceName) {
  width: 30%;
}

:local(.used) {
  width: 10%;
}

:local(.maxUsages) {
  width: 40%;
}

:local(.maxUsagesDiv) {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
     display: block;
     margin-top: 2.0rem;
     
     div:only-child:last-child {
       margin-top: 0.5rem;
     }
  }
}

:local(.period) {
  width: 20%;
}
