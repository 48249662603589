@import "src/styles/colors";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/breakpoints";

:local(.withBorderBottom) {
    overflow-x: scroll;
    -ms-overflow-style: none !important;
    /* for Internet Explorer, Edge */
    scrollbar-width: none !important;

    /* for Firefox */
    &::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }

    width: calc(100% + 48px);
    margin-left: -32px !important;
    margin-right: -32px !important;

    @include media-breakpoint-down("xs") {
        margin-left: -12px !important;
        margin-right: -12px !important;
        border-bottom: 1px solid $gray-200;
    }
}